// Define elegant colors
$primary-color: #fff;
$secondary-color: #ff3366;
$dark-color: #333;
$light-color: #f9f9f9;
$error-color: #ff6347;

// Define gradients
$gradient-light: linear-gradient(to right, #00a9a5, #97d9e1);
$gradient-dark: linear-gradient(to right, #667eea, #764ba2);

.accountPage {
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 10px;
  background: $gradient-light; // Light gradient background
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease; // Fade-in animation

  h2 {
    margin-bottom: 30px;
    font-size: 28px;
    color: $dark-color;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); // Text shadow for depth
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
      font-weight: bold;
      color: $dark-color;
    }

    input {
      margin-bottom: 20px;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #ccc;
      font-size: 16px;
      color: $dark-color;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 5px rgba($primary-color, 0.5); // Highlight on focus
      }
    }

    button {
      margin-top: 20px;
      padding: 14px;
      border: none;
      border-radius: 8px;
      background-color: $primary-color;
      color: $dark-color;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      &:active {
        transform: translateY(2px); // Pressed effect
      }
    }
  }

  .error {
    margin-top: 10px;
    color: $error-color;
    font-size: 14px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  // Responsive Styling
  @media screen and (max-width: 768px) {
    max-width: 90%;
    padding: 20px;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    form {
      input {
        font-size: 14px;
      }

      button {
        font-size: 14px;
      }
    }
  }
}
