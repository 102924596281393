/* upload.scss */

.user {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .usern {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .user-profile {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      border: 3px solid #ffffff; /* Add a border around the profile image */
    }

    p {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  }

  .video-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px); /* Adjust as needed */
  }

  .video-upload-form {
    width: 500px;
    padding: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;

    h1 {
      font-size: 28px;
      margin-bottom: 30px;
      color: #333333;
      text-transform: uppercase; /* Uppercase the heading */
      letter-spacing: 2px; /* Add letter spacing */
    }

    input[type='file'],
    input[type='text'],
    textarea {
      width: calc(100% - 24px); /* Adjust for padding */
      padding: 12px;
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      font-size: 16px;
      transition: border-color 0.3s ease;
      color: #333333; /* Text color */
      font-family: 'Roboto', sans-serif; /* Use a suitable font */

      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }

    textarea {
      resize: vertical;
      height: 120px;
    }

    button {
      padding: 12px 30px;
      background-color: #00a9a5;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    video {
      width: 100%;
      border-radius: 5px;
      margin-top: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    /* Additional styles for a luxurious look */
    input[type='text'],
    textarea,
   
    input[type='file'] {
      margin-bottom: 30px; /* Increased bottom margin */
    }

    button {
      font-weight: bold;
      text-transform: uppercase; /* Uppercase button text */
      letter-spacing: 1px; /* Add letter spacing */
    }
    
  }
}
// Media Query for tablets
@media screen and (max-width: 992px) {
  .video-upload-form {
    padding: 20px; /* Adjusted padding for smaller screens */
  }
}

// Media Query for mobile devices
@media screen and (max-width: 768px) {
  .user-profile {
    flex-direction: column; /* Stacked user profile on smaller screens */
    text-align: center; /* Center text in user profile */
  }

  .video-upload-form {
    max-width: 100%; /* Adjusted max-width for full-width form */
  }
}