// src/App.scss
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .dashboard,
  .upload-content,
  .not-found {
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .stats-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  
    .stat-box {
      background-color: #f0f0f0;
      padding: 10px;
      border-radius: 8px;
      width: 200px;
      text-align: center;
  
      h3 {
        margin-bottom: 5px;
      }
  
      p {
        font-weight: bold;
      }
    }
  }
  
  input[type='file'] {
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  // Responsive Styling
@media screen and (max-width: 768px) {
  .dashboard,
  .upload-content,
  .not-found {
    padding: 10px;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .stats-container {
    flex-wrap: wrap;

    .stat-box {
      margin-bottom: 15px;
    }
  }
}