// BlogUploader.scss
.blog-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-profile {
    margin-top: 20px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .blog-form {
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;

    h1 {
      margin-bottom: 20px;
    }

    input[type="text"],
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      resize: none;
    }

    button {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}
// Media Query for tablets
@media screen and (max-width: 768px) {
  .blog-form {
    max-width: 90%; // Adjusted max-width for smaller screens
  }
}