.historyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
  margin-left: 200px;

  .historyContent {
    max-width: 1200px;
    width: 100%;
    padding: 40px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      margin-bottom: 30px;
      font-size: 32px;
      color: #333;
      text-transform: uppercase;
    }

    .videoList {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .videoItem {
        width: calc(33.33% - 20px);
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        .videoPlayer {
          width: 100%;
          border-radius: 20px 20px 0 0;
          overflow: hidden;

          video {
            width: 60%;
            border-radius: 20px 20px 0 0;
            align-items: center;
            margin-left: 80px;
            margin-top: 10px;
          }
        }

        .videoDetails {
          padding: 20px;
          background-color: #f9f9f9;
          border-radius: 0 0 20px 20px;

          h3 {
            margin-bottom: 15px;
            font-size: 24px;
            color: #333;
          }

          p {
            margin-bottom: 20px;
            font-size: 16px;
            color: #666;
          }

          .actionButtons {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;

            button {
              padding: 10px 20px;
              border-radius: 5px;
              font-size: 14px;
              text-transform: uppercase;
              font-weight: bold;
              border: none;
              cursor: pointer;
              transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

              &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
              }
            }

            .editButton {
              background-color: #007bff;
              color: #fff;
            }

            .deleteButton {
              background-color: #dc3545;
              color: #fff;
            }
          }
        }
      }
    }
  }

  // Updated styles for the analytics container
  .analyticsContainer {
    max-width: 300px; // Adjust as needed
    width: 40%;
    margin: 20px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    text-align: center;

    h2 {
      margin-bottom: 10px;
      font-size: 24px;
      color: #333;
    }

    canvas {
      width: 60%;;
      max-width: 100%; // Adjust as needed
      margin: 0 auto;
      border-radius: 8px;
    }
  }
}
// Media Query for smaller screens
@media screen and (max-width: 1200px) {
  .historyPage {
    margin-left: 0; // Remove left margin for better responsiveness
  }

  .historyContent {
    padding: 20px; // Adjust padding for smaller screens
  }

  .analyticsContainer {
    width: 90%; // Adjust width for smaller screens
  }
}

// Media Query for mobile devices
@media screen and (max-width: 768px) {
  .videoItem {
    width: 100%; // Adjust width for full width on mobile devices
  }

  .videoPlayer video {
    margin-left: 0; // Remove left margin for better alignment
  }
}