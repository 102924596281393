.dashboard {
    .stats-container {
        margin-left: 150px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 40px; /* Increased margin for better spacing */
    
        .stat-box {
          background: linear-gradient(135deg, #00a9a5, #00f9a9); /* Gradient background */
          color: #fff; /* Text color */
          padding: 10px; /* Increased padding for a more prominent look */
          border-radius: 10px; /* Rounded corners */
          width: 150px; /* Increased width */
          height: 100px;
          text-align: center;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced box shadow */
    
          h3 {
            margin-bottom: 15px; /* Increased margin for title */
            font-size: 15px; /* Larger font size for title */
          }
    
          p {
            font-size: 20px; /* Larger font size for numbers */
            font-weight: bold;
            margin-bottom: 20px; /* Increased margin for numbers */
          }
    
          svg {
            width: 50px;
            height: 50px;
            margin-bottom: 15px; /* Adjusted margin for icon spacing */
            fill: #fff; /* Icon color */
          }
    
          transition: transform 0.3s ease; /* Added transition for hover effect */
    
          &:hover {
            transform: translateY(-5px); /* Added hover effect */
          }
        }
      }
  
    .analytics-container {
      margin-top: 30px;
  
      h2 {
        margin-bottom: 10px;
      }
  
      canvas {
        width: 20%;
        max-width: 600px; // Adjust as needed
        margin: 20px auto;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      }
    }
  }
 // Media Query for tablets
@media screen and (max-width: 768px) {
  .dashboard .stats-container {
    flex-wrap: wrap; /* Allow flex items to wrap */
    justify-content: center; /* Center items */
  
    .stat-box {
      margin: 10px; /* Adjust margin for spacing */
    }
  }

  .dashboard .analytics-container {
    h2 {
      text-align: center; /* Center align heading */
    }
  }
}